:root {
  /**************************************************************/
  /**************************************************************/
  /* Primary palette */
  /**************************************************************/
  /**************************************************************/
  --scbWhite: #fff;
  --scbCloud: #F2F2F2;
  --scbStone: #E2DCDC;
  --scbNightSky: #141414;
  --scbOcean: #000661;
  --scbBlue: #161893;
  /**************************************************************/
  /**************************************************************/
  /* Secondary palette */
  /**************************************************************/
  /**************************************************************/
  --scbCanopy: #15BE78;
  --scbSunset: #F9A03F;
  --scbDesert: #FF664A;


  --darkGrey: #616161;
  --pink: #F06292;
  --pinkLight: #FCE4EC;

  --gs: #FF664A;
  --vcs: #15BE78;
  --cdm: #161893;

  --renewables: #F9A03F;
  --forestry: #15BE78;
  --cryptos: #161893;

  --negative: red;
  --positive: green;

  --bootstrapPrimary: #0d6efd;
  --bootstrapWarning: #ffc107;
  --bootstrapSuccess: #198754;
  --bootstrapDanger: #dc3545;

  /**************************************************************/
  /**************************************************************/
  /****** Global parameters that can be dynamically changed
  /**************************************************************/
  /**************************************************************/
  --main-font: 'Comfortaa', 'Quicksand', 'Open Sans', 'Merriweather Sans', 'Roboto Condensed', 'Trispace', 'Roboto';

  --main-navbar-background: var(--scbOcean);
  --main-font-color-light: var(--scbCloud);
  --main-font-color: var(--scbNightSky);

  --main-border-colour: var(--scbNightSky);

  --main-background-color: var(--scbCloud);
  --main-form-background: var(--scbCloud);

  --main-font-color-offsetting: var(--scbCanopy);


  --scb-project-background: var(--scbCloud);
  --scb-project-font-color: var(--scbNightSky);

  --border-style: 'solid';

  --border-width: '1px';

  --border-width-banner: '1px';


  --main-pdf-logo: var(--scbOcean);


} /* END OF ROOT */


/* ICONS: https://icons.getbootstrap.com/         <i class="bi bi-check-circle-fill text-success"> is LDC</i>       */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");



/**************************************************************/
/**************************************************************/
/****** Inputs size
/**************************************************************/
/**************************************************************/

input.form-control {
  height: 2em;
}

.input-group {
  height: 1.5em;
}

.input-group-text {
  height: 2em;

}

/**************************************************************/
/**************************************************************/
/****** Global parameters
/**************************************************************/
/**************************************************************/

/* FONTS: https://fonts.google.com/specimen/Roboto */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Lato:wght@400;700&family=Merriweather+Sans:wght@300&family=Modak&family=Open+Sans:wght@500&family=Quicksand:wght@600&family=Raleway:wght@300&family=Roboto+Condensed&family=Roboto:wght@300;700&family=Trispace:wght@500&display=swap');


body {
  /*margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  background-color: var(--main-background-color);
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/*.Navbar {
  background: var(--navbar-bg-color);
  height: 2.5em
}*/

/*.Navbar-Title {
  font-family: var(--main-font);
  font-size: 0.8em;
}*/

/*.TickerBar {
  color: var(--scbCloud);
  font-size: 0.7em;
}*/

/*.Card {
  width: '100%';
  background: var(--scbNightSky);
  margin-top: var(--card-margin-top);
  padding-bottom: var(--card-padding-bottom);
}*/

/*.Card-Header {
  background: var(--scbNightSky);
  color: var(--card-header-color);
  font-size: var(--card-header-font-size);
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.3em;
  padding-right: 0.3em;

}

.Card-Body {
  background: var(--card-body-bg-color);
  color: var(--card-body-color);
  padding-top: 0.3em;
  padding-bottom: 0.6em;
  padding-left: 0.2em;
  padding-right: 0.2em;

}*/



/* *********************************************** */
/* *********************************************** */
/* *************** Global setup ***************** */
/* *********************************************** */
/* *********************************************** */

/* Resolving the problem: React-Bootstrap causing margins on left and right side 
https://stackoverflow.com/questions/46151515/react-bootstrap-causing-margins-on-left-and-right-side
*/

/*div.container-fluid {
    padding-left: 0;
    padding-right: 0;
}*/

div.row {
  margin-right: 0px;
  margin-left: 0px;
}


/* https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius?retiredLocale=de */

.row {
  font-family: var(--main-font);
}

.main {
  background-color: var(--scbWhite);
  font-family: var(--main-font);

}

.navbar {
  background-color: var(--main-navbar-background);
  font-family: var(--main-font);
}


/* *********************************************** */
/* *********************************************** */
/* *************** Banner top ***************** */
/* *********************************************** */
/* *********************************************** */

.bannerTop {
  border-top: var(--main-navbar-background) 0em solid;
  border-bottom: var(--main-navbar-background) var(--border-width-banner-bottom) solid;
  font-size:small;
  font-family: var(--main-font);
  position: relative;
}


.video-container {
  position: relative;
  /*bottom: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;*/

  /* Very important element: The overflow is clipped, and the rest of the content will be invisible*/
  overflow: hidden;
  border-bottom: var(--main-navbar-background) var(--border-width-banner-bottom) solid;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}


/* *********************************************** */
/* *********************************************** */
/* *************** Web form ***************** */
/* *********************************************** */
/* *********************************************** */

.form {
  background-color: var(--main-form-background);
  color: var(--main-font-color);
  font-family: var(--main-font);
  /*border-radius: 25% 10%;*/
  /*border-radius: 5.5em 1em;*/
  border-radius: 0.5em;
  font-size: 0.9em;
  border: var(--main-border-colour) 0em dotted;
  padding: 0.7em;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);

  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
}

.formProjects2 {
  padding: 0.2em;

  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
}

.formProjects {
  color: var(--main-font-color);
  font-family: var(--main-font);
  /*border-radius: 25% 10%;*/
  /*border-radius: 5.5em 1em;*/
  border-radius: 0.5em;
  border: var(--main-border-colour) 0em solid;
  padding: 0.7em;
  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);*/

  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
}



.formAddress {
  background-color: var(--main-navbar-background);
  color: var(--main-font-color);
  font-family: var(--main-font);
  /*border-radius: 25% 10%;*/
  /*border-radius: 5.5em 1em;*/
  border-radius: 0.5em;
  border: var(--main-border-colour) 2px solid;
  padding: 0.7em;
  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);*/

  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
}

.formGauges {
  background-color: var(--main-background-color);
  color: var(--main-font-color);
  font-family: var(--main-font);
  /*border-radius: 25% 10%;*/
  /*border-radius: 5.5em 1em;*/
  border-radius: 0.5em;
  border: var(--main-border-colour) var(--border-width) var(--border-style);
  padding: 0.7em;
  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);*/

  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
}


.formTickers {
  background-color: #E3F2FD;  /* #E0E0E0; */
  /*color: var(--main-font-color);
  font-family: 'Roboto';*/
  border-radius: 0.5em;
  /*border: var(--main-border-colour) var(--border-width) var(--border-style);*/
  /*padding: 0px;*/
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.5);


}



.accordionBody {
  background-color: var(--main-form-background);
  /*border-radius: 25% 10%;*/
  /*border-radius: 5.5em 1em;*/
  /*border-radius: 1em;*/
  /*border: var(--scbNightSky) 0.2em solid;*/
}


/* *********************************************** */
/* *********************************************** */
/* *************** Cart ***************** */
/* *********************************************** */
/* *********************************************** */
.cart {
  /*background-color: var(--scbCanopy);*/
}


/* *********************************************** */
/* *********************************************** */
/* *************** Carousel ***************** */
/* *********************************************** */
/* *********************************************** */

.carousel {
  /*border-top-left-radius: 1%;
border-top-right-radius: 1%;
border-bottom-left-radius: 1%;
border-bottom-right-radius: 1%;
  padding: 0em;*/
  /*border: var(--main-navbar-background) 0.1em solid;*/

  /*border-radius: 1em;
  border: var(--main-border-colour) 0.1em solid;
  font-family: var(--main-font);
  background-color: var(--main-form-background);*/
}


/* *********************************************** */
/* *********************************************** */
/* *************** Switch ***************** */
/* *********************************************** */
/* *********************************************** */

#custom-switch-offsetting.form-check-input:checked {
  background-color: var(--main-form-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-certificate.form-check-input:checked {
  background-color: var(--main-form-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-styling.form-check-input:checked {
  background-color: var(--main-form-background);
  border-color: var(--scbWhite);
}


#custom-switch-offsetting.form-check-input {
  background-color: var(--main-form-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-certificate.form-check-input {
  background-color: var(--main-form-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-styling.form-check-input {
  background-color: var(--main-form-background);
  border-color: var(--scbWhite);
}





#custom-switch-compact.form-check-input:checked {
  background-color: var(--main-navbar-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-midSize.form-check-input:checked {
  background-color: var(--main-navbar-background);
  border-color: var(--main-navbar-background);
}

#custom-switch-fullSize.form-check-input:checked {
  background-color: var(--main-navbar-background);
  border-color: var(--main-navbar-background);
}

/* *********************************************** */
/* *********************************************** */
/* *************** Card ***************** */
/* *********************************************** */
/* *********************************************** */

.card {
  background-color: var(--main-form-background);
  color: var(--main-font-color);
  border: 0px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);

  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);*/
}



.card-img-top {
  border: var(--main-form-background) 0em solid;
}

.text-center .card {
  /*background-color: var(--scb-project-background);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);*/
}


.card-title {
  font-size:small;
  color: var(--main-font-color);
/*border-top-left-radius: 0em;
border-top-right-radius: 0em;
border-bottom-left-radius: 0.1em;
border-bottom-right-radius: 0.1em;*/
}

.card-text {
  /*background-color: var(--scbCanopy);*/
  font-size: small;    
  color: var(--main-font-color);
  /*font-family: var(--main-font);*/
/*border-top-left-radius: 0em;
border-top-right-radius: 0em;
border-bottom-left-radius: 0.1em;
border-bottom-right-radius: 0.1em;*/
}



.howto .card {
  background-color: var(--main-form-background);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}

.howto .card-title {
  font-size:large;
  color: var(--main-font-color);
/*border-top-left-radius: 0em;
border-top-right-radius: 0em;
border-bottom-left-radius: 0.1em;
border-bottom-right-radius: 0.1em;*/
}

.howto .card-text {
  /*background-color: var(--scbCanopy);*/
  font-size:medium;    
  color: var(--main-font-color);
  /*font-family: var(--main-font);*/
/*border-top-left-radius: 0em;
border-top-right-radius: 0em;
border-bottom-left-radius: 0.1em;
border-bottom-right-radius: 0.1em;*/
}



/* *********************************************** */
/* *********************************************** */
/* *************** Text offsetting ***************** */
/* *********************************************** */
/* *********************************************** */

.text-offsetting {
  /*color: var(--main-font-color-offsetting);*/
  text-align: left;
}


/* *********************************************** */
/* *********************************************** */
/* *************** Badge ***************** */
/* *********************************************** */
/* *********************************************** */


.badgeProjectCountryHeader {
  color: var(--main-font-color);
  font-size: 0.8em;
}

.badgeProjectCountry {
  background-color: var(--main-navbar-background);
  color: var(--main-font-color-light);
  font-size: 0.8em;

}

.badgeTonnesLight {
  color: var(--main-font-color);
  font-size: 0.7em;

}

.badgeCompany {
  color: var(--main-font-color-light);
  font-size:2rem;
  padding: 0.3em;
  background-color: var(--main-navbar-background);
  border: var(--main-border-colour) var(--border-width-badge) solid;
  border-radius: 0.4em;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
  font-family: var(--main-font);

}

.badgeFlight {
  color: var(--main-font-color-light);
  padding: 0.1em;
  background-color: var(--main-navbar-background);
  /*border: var(--main-form-background) var(--border-width-badge) solid;*/
  border-radius: 0.3em;
}

.btnSubmit {
  background-color: var(--bootstrapWarning);
  color: var(--main-font-color);
  padding: 0.3em;
  border: var(--main-border-colour) 0em solid;
  border-radius: 0.4em;

  /*font-size: medium;*/
  /*font-weight: bold;*/
}

.btnSubmitSuccess {
  background-color: var(--scbCanopy);
  color: var(--main-font-color-light);
  padding: 0.3em;
  border: var(--main-border-colour) 0em solid;
  border-radius: 0.4em;

  /*font-size: medium;*/
  /*font-weight: bold;*/
}


.list-group{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);

}




a.carbonCompensateDocUrl:link {color:#fff;}
a.carbonCompensateDocUrl:visited {color:#fff;}
a.carbonCompensateDocUrl:hover {color:#ccc;}




/* Customize modal overlay */
.modal-backdrop {
  /*background-image: url('../img/sea1.jpg');*/
  background-repeat: no-repeat;
  background-size: cover; 
  color: #000;

}


#myModal .modal-content {
  background-color: var(--main-form-background);
  color: var(--main-font-color);
}



.custom-colour-pannel-icon {
  color: var(--main-form-background); /* Use the CSS variable for the icon color */
}


.hand-cursor {
  cursor: pointer;
}


.form-control {
  font-size: 1em;
}

.form-select {
  font-size: 0.9em;

}